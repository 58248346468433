import React, { useState, type FC, useEffect } from 'react'
import Card from '../card'
import AssetBucketsPopUp from '../../pages/Cap-Trader/asset-buckets'
import AssetsCheckerBody from '../../pages/Cap-Trader/assets-checker'
import { type RenderAssetSectionProps } from '../../utility/types'
import RiskAssetViewer from '../../pages/Risk-Officer/RiskAssetBucketPopup'

const RenderAssetSection: FC<RenderAssetSectionProps> = ({
  assetType,
  data,
  refetch,
  setMessage,
  setMessageColor,
  type
}) => {
  const [assetsData, setAssetsData] = useState(data.getRawAssetSumByDates.map(asset => ({
    ...asset,
    ttlNotional: asset.TotalEstimatedNotional,
    ttlQuantity: assetType === 'EQTY' ? asset.TotalQtyEqty : asset.TotalQtyFI
  })))

  useEffect(() => {
    setAssetsData(data.getRawAssetSumByDates.map(asset => ({
      ...asset,
      ttlNotional: asset.TotalEstimatedNotional,
      ttlQuantity: assetType === 'EQTY' ? asset.TotalQtyEqty : asset.TotalQtyFI
    })))
  }, [data.getRawAssetSumByDates, assetType])

  return (
    <>
      <h3 className="text-xl font-semibold my-4">{assetType}</h3>
      <div className='flex flex-wrap'>
        {assetsData
          .filter((assets) => assets.AssetType.includes(assetType))
          .map((assets) => {
            const isClickable = assets.ApprovalStatus !== 'ASSET-LOADED'
            const BasketOverallPass = assets.BasketOverall && assets.TransactionType === 'Deal'
            return (
              <Card
                key={assets.BatchId}
                refetch={refetch}
                isClickable={isClickable}
                batchUniqueId={assets.BatchUniqueId}
                CardBodyComponent={(cardBodyProps) =>
                  <AssetsCheckerBody
                    data={assets}
                    ttlNotioanl={assets.ttlNotional}
                    reconcileIndicator={assets.ReconcileIndicator}
                    reconcileCount={assets.ReconcileCount}
                    {...cardBodyProps}
                  />}
                PopupComponent={(popupProps) =>
                  type === 'cap'
                    ? <AssetBucketsPopUp
                      batchId={assets.BatchId}
                      batchUniqueId={assets.BatchUniqueId}
                      assetType={assetType}
                      setMessage={setMessage}
                      setMessageColor={setMessageColor}
                      status={assets.ApprovalStatus}
                      basketOverall={BasketOverallPass}
                      fundingRequestCode={assets.FundingRequestCode}
                      ttlNotional={assets.ttlNotional}
                      ttlQuantity={assets.ttlQuantity}
                      reconcileIndicator={assets.ReconcileIndicator}
                      reconcileCount={assets.ReconcileCount}
                      {...popupProps}
                    />
                    : <RiskAssetViewer
                      batchId={assets.BatchId}
                      batchUniqueId={assets.BatchUniqueId}
                      setMessage={setMessage}
                      assetType={assetType}
                      setMessageColor={setMessageColor}
                      status={assets.ApprovalStatus}
                      basketOverall={BasketOverallPass}
                      fundingRequestCode={assets.FundingRequestCode}
                      ttlNotional={assets.ttlNotional}
                      ttlQuantity={assets.ttlQuantity}
                      reconcileIndicator={assets.ReconcileIndicator}
                      reconcileCount={assets.ReconcileCount}
                      {...popupProps} />}
                showCheckBoxes={false} />
            )
          })}
      </div>
    </>
  )
}

export default RenderAssetSection
